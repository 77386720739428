function initialState () {
  return {
    authenticated: null,
    id: null,
    name: null,
    email: null,
    createdAt: null,
    updatedAt: null
  }
}

export default {
  state: initialState,
  mutations: {
    setAuthStatusX(state, status) {
      state.authenticated = status;
    },
    loginUserX(state, userData) {
      state.authenticated   = true;
      state.id              = userData.id;
      state.name            = userData.name;
      state.email           = userData.email;
      state.createdAt       = userData.created_at;
      state.updatedAt       = userData.updated_at;
    },
    logoutUserX(state) {
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
  },
  getters: {
    getUserX(state) {
      return state;
    },
    getAuthStatusX(state) {
      return state.authenticated;
    }
  }
}