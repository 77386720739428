import store from '../store';
import axios from 'axios';
axios.defaults.withCredentials = true;

const apiUrl = store.state.apiUrl;
const baseUrl = store.state.baseUrl;

export default {
  csrfCookie() {
    console.log(baseUrl);
    return axios({
      method: 'get',
      url: baseUrl + 'sanctum/csrf-cookie'
    });
  },
}