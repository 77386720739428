<template>
  <div class="text-center uppercase text-4xl text-bi-blue font-bold">
    loading the awesome
  </div>
</template>

<script>
  export default {

  }
</script>