<template>
  <div>
    <router-view v-slot="slotProps">
      <transition enter-active-class="animate__animated animate__faster animate__fadeIn" leave-active-class="animate__animated animate__faster animate__fadeOut" name="route" mode="out-in">
        <component :is="slotProps.Component"></component>
      </transition>
    </router-view>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import Loading from './components/global/Loading'
  import 'animate.css';
  import miscRequests from './helpers/miscRequests';


  export default {
    components: {
      Loading
    },
    mounted() {
      this.setAppLoadedX(true);
      // miscRequests.csrfCookie()
      // .then((response) => {
      //   this.setAppLoadedX(true);
      // }).catch(error => {
      //   console.log('could not set csrf');
      //   console.log(error);
      // });
    },
    methods: {
      ...mapMutations([
        'setAppLoadedX'
      ])
    },
    computed: {
      ...mapGetters([
        'getAppLoadedX'
      ]),
    }
  }
</script>

<style>
  /* @font-face {
    font-family: "Worksans";
    src: url(./fonts/worksans.ttf);
  } */
  .font-raleway {
    font-family: 'Raleway', sans-serif;
  }
  .printotype-shadow {
    box-shadow: 5px 5px 15px 0px #45bdac;
  }
</style>