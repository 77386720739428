import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('../components/global/Layout'),
    children: [
      {
        path: '/',
        redirect: '/services'
      },
      // {
      //   path: '/news',
      //   name: 'news',
      //   component: () => import('../views/News/News.vue'),
      // },
      {
        path: '/3d-printing',
        name: '3d-printing',
        component: () => import('../views/Printing/Printing.vue'),
      },
      {
        path: '/portfolio',
        name: 'portfolio',
        component: () => import('../views/Portfolio/Portfolio.vue'),
      },
      {
        path: '/services',
        name: 'services',
        component: () => import('../views/Services/Services.vue'),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/About/About.vue'),
      },
      {
        path: '/pricing',
        name: 'pricing',
        component: () => import('../views/Pricing/Pricing.vue'),
      },
      // {
      //   path: '/e-store',
      //   name: 'e-store',
      //   component: () => import('../views/Estore/Estore.vue'),
      // },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/Contact/Contact.vue'),
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router