import { createStore } from 'vuex';
import moduleUser from './modules/user';

function initialState () {
	return {
    appLoaded: false,
    navDrawerVisible: false,
    apiUrl: process.env.VUE_APP_API_URL,
    baseUrl: process.env.VUE_APP_BASE_URL,
    title: process.env.VUE_APP_TITLE
	}
}

export default createStore({
  state: initialState,
  mutations: {
    setAppLoadedX(state, status) {
      state.appLoaded = status;
    },
    setNavDrawerVisibleX(state, visibility) {
      state.navDrawerVisible = visibility;
    }
  },
  getters: {
    getAppLoadedX(state) {
      return state.appLoaded;
    },
    getNavDrawerVisibleX(state) {
      return state.navDrawerVisible;
    }
  },
  actions: {
  },
  modules: {
    user: moduleUser
  }
})
