import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css/tailwind.css';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3';

import Toaster from '@meforma/vue-toaster';

createApp(App)
  .use(store)
  .use(router)
  .use(Toaster)
  .use(TawkMessengerVue, {
    propertyId : '64a2c5f194cf5d49dc613e11',
    widgetId : '1h4dtae6v'
  })
  .mount('#app');